import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { setExtra, captureException } from '@sentry/browser';

import { AxiosResponse } from 'axios';
import {
  renderAlreadyAppliedToaster,
  renderEventAlreadyRegisteredToaster,
  renderEventAlreadyWaitlistedToaster,
  renderInvalidURLReportToaster,
} from 'components/layouts/Header/Header.component';
import { post, get, patch } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';

import { FollowingResponse, FollowingsResponse } from 'lib/models/following';
import { ApplicationResponse } from 'lib/models/application';
import { OpportunitiesResponse } from 'lib/models/opportunity';
import { Notice } from 'lib/models/notice';
import { MatchUpdateResponse } from 'lib/models/matches';
import { TouchpointType } from 'lib/models/touchpoint';
import { UserRole } from 'lib/models/user';
import { apiInstance } from 'lib/utils/axios';
import { parseAxiosErrorAlert, parseResponse } from 'lib/utils/parser';
import { ERROR_MESSAGE } from 'lib/constants';
import { StudentSocietyRegistration } from 'lib/models/student-society-registration';
import { isMobileDevice } from 'utils/dom';
import { useNotification } from './useNotification';

export const useCommon = () => {
  const notificationInstance = useNotification();
  const router = useRouter();
  const { utm_source } = router.query;
  const { data: session, status } = useSession();

  const followEntity = async ({
    followable_type,
    followable_id,
  }: {
    followable_type: string;
    followable_id: string;
    headers?: Headers;
  }) => {
    const body = {
      followable_type,
      followable_id,
      utm_source,
    };
    try {
      const { data } = await apiInstance.post<FollowingResponse>(
        API_ROUTES.FOLLOW,
        body
      );
      return data;
    } catch (error) {
      console.error(error);
      const message = parseAxiosErrorAlert(error);
      if (message === ERROR_MESSAGE.company_already_following)
        notificationInstance.acknowledge({ message: 'Already following' });
      else
        notificationInstance.handleExceptionError({ title: 'Error', message });
      return null;
    }
  };

  const checkIfCandidateEmail = async (email: string) => {
    try {
      await apiInstance.post(API_ROUTES.IS_CANDIDATE_EMAIL, {
        email,
      });
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const applyOpportunity = async (touchpoint_id: string) => {
    const body = {
      touchpoint_application: { touchpoint_id, utm_source },
    };
    const pathname = `${API_ROUTES.CAMPAIGNS}/${touchpoint_id}${API_ROUTES.APPLICATIONS}`;
    try {
      const { data } = await apiInstance.post<ApplicationResponse>(
        pathname,
        body
      );
      return data;
    } catch (error) {
      const message = parseAxiosErrorAlert(error);
      if (message === ERROR_MESSAGE.touchpoint_already_applied) {
        notificationInstance.custom({
          message: '',
          timeout: 10000,
          renderContent: renderAlreadyAppliedToaster,
        });
      } else {
        captureException(error);
        setExtra('touchpoint_id', touchpoint_id);
        notificationInstance.handleExceptionError(error);
      }
    }
  };

  const removeOpportunity = async (
    id: string,
    headers: Headers,
    status: string
  ) => {
    const pathname = `${API_ROUTES.CANDIDATE_TOUCHPOINT}/${id}`;
    const body = {
      move_to: status,
      from_application: false,
    };
    try {
      const response = await patch<OpportunitiesResponse>(
        pathname,
        body,
        headers
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const followedEntity = async ({
    followable_type,
    headers,
  }: {
    followable_type: string;
    headers: Headers;
  }) => {
    const pathname = `${API_ROUTES.FOLLOW}?with_details=true&followable_types[]=${followable_type}`;
    try {
      const response = await get<FollowingsResponse>(pathname, headers);
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const filterTouchpoint = async ({
    action_type,
    headers,
  }: {
    action_type: string;
    headers: Headers;
  }) => {
    const pathname = `${API_ROUTES.CAMPAIGNS}?action_type=${action_type}`;
    try {
      const response = await get<OpportunitiesResponse>(pathname, headers);
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onShare = async (
    title: string,
    url: string,
    notificationMessage?: Record<string, string>
  ) => {
    if (navigator.share && isMobileDevice()) {
      try {
        await navigator.share({
          title,
          url,
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(url);
          if (notificationMessage) {
            const fn = notificationMessage.acknowledge
              ? notificationInstance.acknowledge
              : notificationInstance.success;
            fn({
              title: notificationMessage.title,
              message: notificationMessage.message,
            });
          } else {
            notificationInstance.info({
              title: 'URL copied to clipboard',
              message: 'Paste to share the URL',
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const unfollowEntity = async ({ id }: { id: string; headers?: Headers }) => {
    const pathname = `${API_ROUTES.FOLLOW}/${id}`;
    try {
      const { data } = await apiInstance.delete<FollowingResponse>(pathname);
      return data;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const sendInvalidURLReport = async ({ id }: { id: string }) => {
    const body = {
      invalid_url_report: {
        touchpoint_id: id,
      },
    };
    try {
      await apiInstance.post<Notice>(API_ROUTES.INVALID_URL_REPORT, body);
      notificationInstance.custom({
        message: '',
        timeout: 3600000,
        renderContent: renderInvalidURLReportToaster,
      });
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const dislikeEntity = async (
    id: string,
    skipped_at: Date,
    headers: Headers
  ) => {
    const pathname = `${API_ROUTES.DISLIKE_MATCH}/${id}`;
    const body = {
      match: {
        skipped_at,
      },
    };
    try {
      const response = await patch<OpportunitiesResponse>(
        pathname,
        body,
        headers
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const removeDislike = async (id: string, headers: Headers) => {
    const pathname = `${API_ROUTES.DISLIKE_MATCH}/${id}`;
    const body = {
      match: {
        skipped_at: null,
      },
    };
    try {
      const response = await patch<OpportunitiesResponse>(
        pathname,
        body,
        headers
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const registerEvent = async ({
    touchpoint_id,
    touchpointable_type,
    first_name,
    last_name,
    email,
    is_privacy_terms_accepted,
    study_year,
    linkedInURL,
    subject_id,
    degree_id,
    university_id,
    resume_filename,
    resume_file,
    answer,
    graduation_year,
    education_histories_id,
    isJoinWaitlist,
  }: {
    touchpoint_id: string;
    touchpointable_type: TouchpointType;
    first_name?: string;
    last_name?: string;
    email: string;
    is_privacy_terms_accepted: boolean;
    study_year?: number;
    linkedInURL?: string;
    subject_id?: string;
    degree_id?: string;
    university_id?: string;
    resume_filename?: string;
    resume_file?: string;
    graduation_year?: number;
    education_histories_id?: string;
    answer?: Array<Record<string, string | string[]>>;
    isJoinWaitlist?: boolean;
  }) => {
    const body = {
      touchpoint_application: {
        touchpoint_id,
        candidate_attributes: {
          first_name,
          last_name,
          email,
          study_year,
          graduation_year,
          linkedin_url: linkedInURL,
          education_histories_attributes: [
            {
              id: education_histories_id,
              subject_id,
              degree_id,
              university_id,
              education_type: 'university',
              skip_validation: true,
            },
          ],
          resume: {
            name: resume_filename,
            file: resume_file,
          },
          is_privacy_terms_accepted,
        },
        answers_attributes: answer,
        utm_source,
      },
    };
    const pathname = `${API_ROUTES.CAMPAIGNS}/${touchpoint_id}${API_ROUTES.APPLICATIONS}`;
    try {
      const { data } = await apiInstance.post<ApplicationResponse>(
        pathname,
        body
      );
      return data;
    } catch (error) {
      const message = parseAxiosErrorAlert(error);
      if (message === ERROR_MESSAGE.touchpoint_already_applied) {
        if (isJoinWaitlist) {
          notificationInstance.custom({
            message: '',
            timeout: 10000,
            renderContent: renderEventAlreadyWaitlistedToaster,
          });
        } else {
          notificationInstance.custom({
            message: '',
            timeout: 10000,
            renderContent:
              touchpointable_type === TouchpointType.Event
                ? renderEventAlreadyRegisteredToaster
                : renderAlreadyAppliedToaster,
          });
        }
      } else {
        captureException(error);
        setExtra('touchpoint_id', touchpoint_id);
        notificationInstance.handleExceptionError(error);
      }
    }
  };

  const saveMatch = async ({
    match_id,
    headers,
  }: {
    match_id: string | undefined;
    headers: Headers;
  }) => {
    const body = {
      match: {
        saved_at: new Date().toISOString(),
      },
    };
    try {
      const response = await patch<MatchUpdateResponse>(
        `${API_ROUTES.UPDATE_MATCHES}/${match_id}`,
        body,
        headers
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const unSaveMatch = async ({
    match_id,
    headers,
  }: {
    match_id: string | undefined;
    headers: Headers;
  }) => {
    const body = {
      match: {
        saved_at: null,
      },
    };
    try {
      const response = await patch<MatchUpdateResponse>(
        `${API_ROUTES.UPDATE_MATCHES}/${match_id}`,
        body,
        headers
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onApplyClick = async ({ touchpointId }: { touchpointId: string }) => {
    const pathname = `${API_ROUTES.CANDIDATE_TOUCHPOINT}/${touchpointId}/click_applied`;
    try {
      await apiInstance.patch(pathname, {});
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };
  const viewedCompanies = async ({
    companyId,
    headers,
  }: {
    companyId: string;
    headers: Headers;
  }) => {
    const pathname = `${API_ROUTES.COMPANY_LIST}/${companyId}/viewed`;
    try {
      await post(pathname, {}, headers);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onApproveMember = async ({
    headers,
    id,
  }: {
    headers: Headers;
    id: string;
  }) => {
    const body = {};
    try {
      const response = await post<Notice>(
        `${API_ROUTES.SOCIETY_MANAGERS}/${id}/approve`,
        body,
        headers
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onRejectMember = async ({
    headers,
    id,
  }: {
    headers: Headers;
    id: string;
  }) => {
    const body = {};
    try {
      const response = await post<Notice>(
        `${API_ROUTES.SOCIETY_MANAGERS}/${id}/reject`,
        body,
        headers
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onGoogleLoginAuthentication = async (userType: UserRole) => {
    if (session && status === 'authenticated') {
      const { user, oauth_uid, id_token, access_token, image } = session || {};
      const { name, email } = user || {};
      const first_name = name ? name.split(' ')[0] : '';
      const last_name = name ? name.split(' ')[1] : '';

      const capitalizedUserType =
        userType.charAt(0).toUpperCase() + userType.slice(1);

      const body = {
        email,
        oauth_provider: 'google',
        oauth_uid,
        id_token,
        access_token,
        first_name: first_name || '',
        last_name: last_name || '',
        utm_source,
        avatar: image,
        userable_type: capitalizedUserType,
      };

      try {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const response = await post<any>('/api/v1/oauth_google', body);
        return response;
      } catch (error) {
        console.error(error);
        notificationInstance.handleExceptionError(error);
      }
    }
  };

  const onAppleLoginAuthentication = async (userType: UserRole) => {
    if (session && status === 'authenticated') {
      const { user, oauth_uid, id_token, access_token, image } = session || {};

      const { name, email } = user || {};
      const first_name = name ? name.split(' ')[0] : email;
      const last_name = name ? name.split(' ')[1] : email;

      const capitalizedUserType =
        userType.charAt(0).toUpperCase() + userType.slice(1);

      const body = {
        email,
        oauth_provider: 'apple',
        oauth_uid,
        id_token,
        access_token,
        first_name: first_name || '',
        last_name: last_name || '',
        utm_source,
        avatar: image,
        userable_type: capitalizedUserType,
      };

      try {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const response = await post<any>('/api/v1/oauth_apple', body);
        return response;
      } catch (error) {
        console.error(error);
        notificationInstance.handleExceptionError(error);
      }
    }
  };

  const onJoinSociety = async ({
    email,
    student_society_id,
  }: {
    email: string;
    student_society_id: string;
  }) => {
    const body = {
      student_society_registration: {
        email,
        student_society_id,
      },
    };
    const { data: response } = await apiInstance.post<
      AxiosResponse<StudentSocietyRegistration>
    >(API_ROUTES.CANDIDATE_SIGNUP, body);
    if (response) {
      return parseResponse(response);
    } else {
      throw new Error('Error joining society');
    }
  };

  const onDisjoinSociety = async ({ id }: { id: string }) => {
    const body = {
      student_society_id: id,
    };
    return await apiInstance.post<Notice>(
      `${API_ROUTES.STUDENT_SOCIETY_REGISTRATION}/unsubscribe`,
      body
    );
  };

  return {
    followEntity,
    applyOpportunity,
    checkIfCandidateEmail,
    removeOpportunity,
    followedEntity,
    filterTouchpoint,
    onShare,
    unfollowEntity,
    sendInvalidURLReport,
    dislikeEntity,
    removeDislike,
    registerEvent,
    saveMatch,
    unSaveMatch,
    onApplyClick,
    onApproveMember,
    viewedCompanies,
    onRejectMember,
    onGoogleLoginAuthentication,
    onAppleLoginAuthentication,
    onJoinSociety,
    onDisjoinSociety,
  };
};
